<template>
    <imis-panel-field v-slot="slot"
        :caption="caption"
        :positionCaption="positionCaption"
        :size="size"
        :info="info"
        :autoWidth="autoWidth"
        :disabled="disabled"
        :required="required"
        :valid="valid"
        :error="error" >

        <input v-if="rows === 1"
            :type="conceal ? 'password' : 'text'"
            :id="slot.uid"
            :class="slot.size"
            :disabled="slot.disabled"
            :placeholder="placeholder"
            :value="value"
            @keypress="isNum? isNumber($event) : ''"
            @input="$emit('input', $event.target.value)" />

        <textarea v-if="rows > 1"
            :id="slot.uid"
            :class="slot.size"
            :disabled="slot.disabled"
            :placeholder="placeholder"
            :rows="rows"
            :value="value"
            @input="$emit('input', $event.target.value)" />

    </imis-panel-field>
</template>

<script>
import ImisPanelField from './ImisPanelField.vue'

export default {
  name: 'ImisTextField',
  components: {
    ImisPanelField
  },
  props: {
    isNum:Boolean,
    value: String,
    caption: String,
    positionCaption: String,
    size: String,
    info: String,
    autoWidth: Boolean,
    disabled: Boolean,
    required: Boolean,
    valid: Boolean,
    error: String,
    placeholder: String,
    conceal: Boolean,
    rows: { type: Number, default: 1 }
  },
  data () {
    return {}
  },
    methods: {
   
      isNumber: function(evt) {
       
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
           console.log(charCode)
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
          } else {
            return true;
          }
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

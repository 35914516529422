<template>
    <div style="clear:both;">
        <span v-if="title" class="Label">{{title}}</span>
        <div class="SubItems RadioButtonList ClearFix">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
  name: 'ImisRadioButtonList',
  props: {
    title: String
  },
  data () {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
